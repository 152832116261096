<template>
    <ion-page>
        <ion-toolbar
            style="--background: #03a9f4; color: white; --min-height: 36px"
            class="custom-toolbar"
        >
            <div class="row align-center">
                <slot name="before">
                    <div class="shrink">
                        <DxButton
                            type="default"
                            icon="mdi mdi-chevron-left"
                            class="ml-1"
                            @click="$router.go(-1)"
                        />
                    </div>
                </slot>
                <slot name="center">
                    <div class="grow text-xs-center">THÔNG TIN THANH TOÁN</div>
                </slot>
                <slot name="after">
                    <div class="shrink" style="opacity: 0">
                        <DxButton
                            icon="mdi mdi-filter-variant"
                            type="default"
                            @click="PopupLocDuLieu = true"
                        />
                    </div>
                </slot>
            </div>
        </ion-toolbar>

        <ion-content :scroll-events="false">
            <div class="px-2 py-3 mb-5">
                <div class="row justify-center font-16 font-medium mb-1">
                    Thông tin vé
                </div>
                <div class="px-2">
                    <div
                        class="row row-don-gia"
                        v-for="(item, index) in DanhSachGiaVe"
                        :key="index"
                    >
                        <div class="xs12">
                            <div :class="rowDonGia">
                                <div class="xs5">
                                    ({{
                                        item.DanhSachCho.map((e) => e.TenCho).join("; ")
                                    }})
                                </div>
                                <div class="xs4 text-xs-right">
                                    <div class="row justify-end color-success">
                                        ({{ item.DanhSachCho.length }}) x
                                        {{ $MoneyFormat(item.GiaVe) }}đ
                                    </div>
                                    <!-- <div
                                        v-if="
                                            ChiTietCauHinhDoanhNghiepTheoMaCauHinh[
                                                $t(
                                                    'MaCauHinhDoanhNghiep.DoanhNghiepSuDungHoaDonBanHangCoGiamTru',
                                                )
                                            ] == 'true' && item.GiaVeSauGiamTru
                                        "
                                        class="row justify-end color-warning"
                                        style="margin-top: 1.5px"
                                        @click="showPopup('PopupSoTienSauGiamTru')"
                                    >
                                        {{ $MoneyFormat(item.GiaVeSauGiamTru) }}đ
                                    </div> -->
                                </div>
                                <div class="xs3 text-xs-right">
                                    <div class="row justify-end color-success">
                                        =
                                        {{
                                            $MoneyFormat(
                                                item.GiaVe * item.DanhSachCho.length,
                                            )
                                        }}đ
                                    </div>
                                    <!-- <div
                                        v-if="
                                            ChiTietCauHinhDoanhNghiepTheoMaCauHinh[
                                                $t(
                                                    'MaCauHinhDoanhNghiep.DoanhNghiepSuDungHoaDonBanHangCoGiamTru',
                                                )
                                            ] == 'true' && item.GiaVeSauGiamTru
                                        "
                                        class="row color-warning"
                                        @click="showPopup('PopupSoTienSauGiamTru')"
                                    >
                                        <i
                                            class="mdi mdi-help-circle-outline font-16 ml-1"
                                        ></i>
                                    </div> -->
                                </div>
                            </div>
                            <div
                                class="row align-center"
                                v-if="
                                    ChiTietCauHinhDoanhNghiepTheoMaCauHinh[
                                        $t(
                                            'MaCauHinhDoanhNghiep.DoanhNghiepSuDungHoaDonBanHangCoGiamTru',
                                        )
                                    ] == 'true' && item.GiaVeSauGiamTru
                                "
                                @click="showPopup('PopupSoTienSauGiamTru')"
                            >
                                <div class="xs5">
                                    <div class="row color-warning">
                                        <i
                                            class="mdi mdi-help-circle-outline font-16 ml-1"
                                        ></i>
                                    </div>
                                </div>
                                <div class="xs4 text-xs-right">
                                    <div class="row justify-end color-warning">
                                        ({{ item.DanhSachCho.length }}) x
                                        {{ $MoneyFormat(item.GiaVeSauGiamTru) }}đ
                                    </div>
                                </div>
                                <div class="xs3 text-xs-right">
                                    <div class="row justify-end color-warning">
                                        =
                                        {{
                                            $MoneyFormat(
                                                item.GiaVeSauGiamTru *
                                                    item.DanhSachCho.length,
                                            )
                                        }}đ
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div :class="rowThanhTien">
                        <div class="xs5 font-medium">Thành tiền</div>
                        <div class="xs4"></div>
                        <div class="xs3 font-medium text-xs-right">
                            <div class="row color-success justify-end">
                                = {{ $MoneyFormat(ThanhTien) }}đ
                            </div>
                            <div
                                class="row color-warning justify-end"
                                v-if="
                                    ChiTietCauHinhDoanhNghiepTheoMaCauHinh[
                                        $t(
                                            'MaCauHinhDoanhNghiep.DoanhNghiepSuDungHoaDonBanHangCoGiamTru',
                                        )
                                    ] == 'true' && ThanhTienSauGiamTru
                                "
                            >
                                = {{ $MoneyFormat(ThanhTienSauGiamTru) }}đ
                            </div>
                        </div>
                    </div>
                </div>

                <DxValidationGroup ref="formValidation">
                    <div class="row justify-center font-16 font-medium mb-1">
                        Thông tin chuyến đi
                    </div>
                    <div class="px-2">
                        <div class="row">Biển kiểm soát</div>
                        <div class="row mb-4 px-2">
                            <div class="xs12">
                                <DxTextBox
                                    v-model="BienKiemSoat"
                                    placeholder="VD: 20A-01234"
                                    styling-mode="underlined"
                                    validationMessageMode="always"
                                    :tabIndex="1"
                                >
                                    <DxValidator ref="">
                                        <DxStringLengthRule
                                            :max="10"
                                            message="Biển kiểm soát không được vượt quá 10 ký tự!"
                                        />
                                    </DxValidator>
                                </DxTextBox>
                            </div>
                        </div>

                        <div class="row">Giờ xuất bến dự kiến</div>
                        <div class="row mb-4 px-2">
                            <div class="xs12">
                                <DxDateBox
                                    v-model="GioXuatBenDuKien"
                                    validationMessageMode="always"
                                    displayFormat="HH:mm dd/MM/yyyy"
                                    dropDownButtonTemplate="customIcon"
                                    type="datetime"
                                    :tabIndex="2"
                                    :useMaskBehavior="true"
                                    ref="refTab1"
                                    styling-mode="underlined"
                                >
                                    <template #customIcon="{}">
                                        <DxButton icon="mdi mdi-calendar" />
                                    </template>
                                </DxDateBox>
                            </div>
                        </div>
                    </div>

                    <div class="row justify-center font-16 font-medium mb-1">
                        Thông tin người mua
                    </div>
                    <div class="px-2">
                        <div class="row">Họ tên người mua</div>
                        <div class="row mb-4 px-2">
                            <div class="xs12">
                                <DxTextBox
                                    v-model="HoVaTen"
                                    placeholder="VD: Nguyễn Văn A"
                                    styling-mode="underlined"
                                    validationMessageMode="always"
                                    :tabIndex="3"
                                >
                                    <DxValidator ref="">
                                        <DxStringLengthRule
                                            :max="128"
                                            message="Họ tên không được vượt quá 128 ký tự!"
                                        />
                                    </DxValidator>
                                </DxTextBox>
                            </div>
                        </div>

                        <div class="row">Số điện thoại</div>
                        <div class="row mb-4 px-2">
                            <div class="xs12">
                                <DxTextBox
                                    v-model="SoDienThoai"
                                    placeholder="VD: 0912345678"
                                    validationMessageMode="always"
                                    mode="tel"
                                    :tabIndex="4"
                                    styling-mode="underlined"
                                    :onKeyUp="onKeyUpSoDienThoai"
                                    valueChangeEvent="keyup"
                                >
                                    <DxValidator ref="refValidateSoDienThoai">
                                        <DxStringLengthRule
                                            :max="13"
                                            message="Số điện thoại không được vượt quá 13 ký tự!"
                                        />
                                        <DxNumericRule
                                            message="Số điện thoại phải là số!"
                                        />
                                        <DxRangeRule
                                            :min="0"
                                            message="Số điện thoại phải là số!"
                                        />
                                        <DxPatternRule
                                            :pattern="phonePattern"
                                            message="Vui lòng nhập đúng định dạng số điện thoại để tiếp tục mua vé!"
                                        />
                                    </DxValidator>
                                </DxTextBox>
                            </div>
                        </div>

                        <div class="row">Email</div>
                        <div class="row mb-4 px-2">
                            <div class="xs12">
                                <DxTextBox
                                    v-model="Email"
                                    placeholder="VD: google@gmail.com"
                                    styling-mode="underlined"
                                    validationMessageMode="always"
                                    :tabIndex="5"
                                >
                                    <DxValidator>
                                        <DxEmailRule
                                            message="Định dạng Email không hợp lệ"
                                        />
                                        <DxStringLengthRule
                                            :max="128"
                                            message="Email không được vượt quá 128 ký tự!"
                                        />
                                    </DxValidator>
                                </DxTextBox>
                            </div>
                        </div>
                    </div>
                </DxValidationGroup>

                <div class="row px-2">
                    <DxCheckBox
                        :disabled="!QuyenTuyChonPhatHanhVe"
                        v-model:value="CheckPhatHanhVe"
                        text="Phát hành vé"
                        class="mr-3"
                    />
                    <DxCheckBox
                        :disabled="!QuyenTuyChonInVe"
                        v-model:value="CheckInVe"
                        text="In Vé"
                        class="mr-3"
                    />
                    <DxCheckBox
                        :disabled="!QuyenTuyChonIn2Lien"
                        v-model:value="CheckIn2Lien"
                        text="In 2 liên"
                    />
                </div>

                <div class="row mb-3">
                    <div class="xs12">
                        <ViDienTuVue
                            @ChonVi="
                                (item) => {
                                    ViDangChon = item;
                                }
                            "
                            :LayDanhSachVi="false"
                        />
                    </div>
                </div>
            </div>

            <div
                class="row mt-3 justify-end"
                style="
                    position: fixed;
                    bottom: 0;
                    border-top: 1px solid #dadce0;
                    left: 0;
                    padding: 12px;
                    background-color: #fff;
                    z-index: 9;
                "
            >
                <DxButton
                    text="XÁC NHẬN THANH TOÁN"
                    type="default"
                    styling-mode="contained"
                    style="width: 100%"
                    @click="BanVe()"
                />
                <!-- @click="$router.push('/tabs/tab1')" -->
            </div>
        </ion-content>

        <XuLyBanVeVue
            v-if="isXuLy"
            :state="state"
            :model="model"
            :msgError="msgError"
            @thulai="dongPopupXuLyBanVe"
            :thongTinThanhToan="thongTinThanhToan"
        />

        <PopupVue
            height="auto"
            v-if="ifPopupSoTienSauGiamTru"
            v-model:dialog="PopupSoTienSauGiamTru"
            heightScrollView="100%"
            title=""
            :useButtonLeft="false"
            :useButtonRight="false"
            class="popup-padding-16"
        >
            <template #content>
                <NotificationVue
                    :Params="ParamSoTienSauGiamTru"
                    :ButtonLeft="false"
                    @click="XacNhanSoTienSauGiamTru"
                />
            </template>
        </PopupVue>
    </ion-page>
</template>

<script>
import { DxValidationGroup } from "devextreme-vue/validation-group";
import { DxTextBox } from "devextreme-vue/text-box";
import { IonContent, IonPage, IonToolbar, onIonViewWillEnter } from "@ionic/vue";
import {
    DxButton,
    DxSelectBox,
    DxValidator,
    DxCheckBox,
    DxRadioGroup,
    DxDateBox,
} from "devextreme-vue";
import {
    DxEmailRule,
    DxNumericRule,
    DxRangeRule,
    DxPatternRule,
    DxStringLengthRule,
} from "devextreme-vue/data-grid";
import ViDienTuVue from "../../../components/ViDienTu.vue";
import { mapMutations } from "vuex";
import XuLyBanVeVue from "../../../components/_Common/XuLyBanVe.vue";
import NotificationVue from "@sonphat/common-v1/components/Notification.vue";
import PopupVue from "@/components/_Common/Popup.vue";

export default {
    components: {
        DxStringLengthRule,
        DxNumericRule,
        DxRangeRule,
        DxPatternRule,
        DxCheckBox,
        DxRadioGroup,
        DxValidationGroup,
        DxTextBox,
        IonContent,
        IonPage,
        DxButton,
        IonToolbar,
        DxSelectBox,
        DxEmailRule,
        DxValidator,
        DxTextBox,
        ViDienTuVue,
        DxDateBox,
        XuLyBanVeVue,
        NotificationVue,
        PopupVue,
    },
    data() {
        return {
            rowDonGia: "row mb-1 align-start justify-space-between ",
            rowThanhTien: "row mb-1 align-center justify-space-between row-thanh-tien",
            SoDoCho: [],
            HoVaTen: null,
            SoDienThoai: null,
            Email: null,
            BienKiemSoat: null,
            GioXuatBenDuKien: null,
            CheckInVe: false,
            CheckIn2Lien: false,
            CheckPhatHanhVe: false,
            ViDangChon: null,
            phonePattern: /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/,
            state: "loading",
            isXuLy: false,
            msgError: null,
            model: {
                GiaVe: 0,
                SoDienThoai: null,
                Vi: {
                    tenVi: null,
                },
            },
            thongTinThanhToan: {},
            QuyenTuyChonPhatHanhVe: false,
            QuyenTuyChonInVe: false,
            QuyenTuyChonIn2Lien: false,
            ifPopupSoTienSauGiamTru: false,
            PopupSoTienSauGiamTru: false,
            ParamSoTienSauGiamTru: {
                state: "Question",
                title: "Thông tin!",
                message_title: "Là số tiền sau giảm trừ!",
                message: "",
            },
        };
    },
    computed: {
        DanhSachChoNgoiBanVe() {
            return this.$store.state.ChuyenDi.DanhSachChoNgoiBanVe;
        },
        DanhSachGiaVe() {
            let DanhSachCho = this.DanhSachChoNgoiBanVe || [];
            let DanhSachGiaVe = [];
            DanhSachCho.forEach((cho) => {
                let check = DanhSachGiaVe.some(
                    (e) =>
                        e.GiaVe == cho.giaVe && e.GiaVeSauGiamTru == cho.giaVeSauGiamTru,
                );
                if (!check) {
                    let data = {
                        GiaVe: cho.giaVe,
                        GiaVeSauGiamTru: cho.giaVeSauGiamTru,
                        DanhSachCho: [cho],
                    };
                    DanhSachGiaVe.push(data);
                } else {
                    let index = DanhSachGiaVe.indexOf(
                        DanhSachGiaVe.filter(
                            (e) =>
                                e.GiaVe == cho.giaVe &&
                                e.GiaVeSauGiamTru == cho.giaVeSauGiamTru,
                        )[0],
                    );
                    DanhSachGiaVe[index].DanhSachCho.push(cho);
                }
            });
            return DanhSachGiaVe;
        },
        ThanhTien() {
            let sum = 0;
            this.DanhSachGiaVe.forEach((e) => {
                sum += e.GiaVe * e.DanhSachCho.length;
            });
            return sum;
        },
        ThanhTienSauGiamTru() {
            let sum = 0;
            this.DanhSachGiaVe.forEach((e) => {
                sum += e.GiaVeSauGiamTru * e.DanhSachCho.length;
            });
            return sum;
        },
        reloadThongTinBanVe() {
            return this.$store.state.ChuyenDi.reloadThongTinBanVe;
        },
        ChiTietCauHinhDoanhNghiepTheoMaCauHinh() {
            return this.$store.state.DVVTLenh.ChiTietCauHinhDoanhNghiepTheoMaCauHinh;
        },
    },
    watch: {
        PopupSoTienSauGiamTru() {
            if (!this.PopupSoTienSauGiamTru) {
                setTimeout(() => {
                    this.ifPopupSoTienSauGiamTru = false;
                }, 300);
            }
        },
    },
    methods: {
        ...mapMutations("ChuyenDi", ["Set"]),
        showPopup(name) {
            this["if" + name] = true;
            this[name] = true;
        },
        XacNhanSoTienSauGiamTru(bool) {
            if (bool == true) {
                this.PopupSoTienSauGiamTru = false;
            }
        },
        BanVe() {
            try {
                let validate = this.$refs.formValidation.instance.validate();
                if (validate.isValid) {
                    if (!this.ViDangChon.id) {
                        this.ThanhToanTienMat();
                    }
                } else {
                    this.$Helper.autoFocusError(validate);
                }
            } catch (error) {
                console.log("🚀 ~ error", error);
            }
        },
        async ThanhToanTienMat() {
            try {
                this.model = {
                    GiaVe: this.ThanhTien,
                    soDienThoai: this.soDienThoai,
                    Vi: {
                        tenVi: this.ViDangChon.tenVi,
                    },
                };
                this.isXuLy = true;
                this.state = "loading";
                let query = {
                    guidChuyenDi: this.$route.query.guidChuyenDi,
                    danhSachChoNgoi: this.DanhSachChoNgoiBanVe,
                    hoTen: this.HoVaTen,
                    soDienThoai: this.SoDienThoai,
                    email: this.Email,
                    phatHanhVe: this.CheckPhatHanhVe,
                    idDiemXuatPhat: this.$route.query.idDiemXuatPhat,
                    tenDiemXuatPhat: this.$route.query.tenDiemXuatPhat,
                    idDiemDen: this.$route.query.idDiemDen,
                    tenDiemDen: this.$route.query.tenDiemDen,
                    bienKiemSoat: this.BienKiemSoat,
                    thoiGianXuatBen: this.GioXuatBenDuKien,
                };
                let rs = await this.$store.dispatch(
                    "NhanVienBanVe/ThucHienBanVeUyThacTienMat",
                    query,
                );
                if (rs.status) {
                    let DanhSachTenCho = this.DanhSachChoNgoiBanVe.map((e) => e.TenCho);
                    localStorage.setItem("TenCho", JSON.stringify([DanhSachTenCho]));
                    localStorage.setItem("CheckInVe", JSON.stringify(this.CheckInVe));
                    localStorage.setItem(
                        "CheckPhatHanhVe",
                        JSON.stringify(this.CheckPhatHanhVe),
                    );
                    localStorage.setItem(
                        "CheckIn2Lien",
                        JSON.stringify(this.CheckIn2Lien),
                    );
                    this.thongTinThanhToan = {
                        maGiaoDich: rs.data.maGiaoDich,
                        bienKiemSoat: this.BienKiemSoat || "",
                        gioXuatBenDuKien: new Date(this.GioXuatBenDuKien).toISOString(),
                        in2Lien: this.CheckIn2Lien,
                        hoTen: this.HoVaTen || "",
                        soDienThoai: this.SoDienThoai || "",
                    };
                    if (this.CheckInVe || this.CheckIn2Lien) {
                        this.state = "inVe";
                        // this.$router.push({
                        //     path: "/Ban-Ve/In-Ve",
                        //     query: {
                        //         maGiaoDich: rs.data.maGiaoDich,
                        //         bienKiemSoat: this.BienKiemSoat || "",
                        //         gioXuatBenDuKien: new Date(
                        //             this.GioXuatBenDuKien,
                        //         ).toISOString(),
                        //         in2Lien: this.CheckIn2Lien,
                        //         hoTen: this.HoVaTen || "",
                        //         soDienThoai: this.SoDienThoai || "",
                        //     },
                        // });
                    } else {
                        this.state = "success";
                    }
                } else {
                    this.state = "error";
                    this.msgError = rs.message;
                }
                this.$stopLoading;
            } catch (error) {
                console.log("🚀 ~ error", error);
                this.state = "error";
                this.msgError = "Lỗi hệ thống!";
            }
        },
        getData() {
            this.queryHeader = this.$route.query;
            this.GiaVe = parseInt(this.queryHeader.giaVe);
            this.BienKiemSoat = this.queryHeader.bienKiemSoat;
            this.GioXuatBenDuKien = new Date(this.queryHeader.gioXuatBenDuKien);
            this.HoVaTen = null;
            this.SoDienThoai = null;
            this.Email = null;
        },
        dongPopupXuLyBanVe() {
            this.isXuLy = false;
        },
        KiemTraQuyenBanVe() {
            this.QuyenTuyChonPhatHanhVe = this.$Helper.KiemTraQuyen(
                this.$t("QuyenBanVe.BanVeTaiBen"),
                this.$t("QuyenBanVe.TuyChonPhatHanhVe"),
            );
            this.QuyenTuyChonInVe = this.$Helper.KiemTraQuyen(
                this.$t("QuyenBanVe.BanVeTaiBen"),
                this.$t("QuyenBanVe.TuyChonInVe"),
            );
            this.QuyenTuyChonIn2Lien = this.$Helper.KiemTraQuyen(
                this.$t("QuyenBanVe.BanVeTaiBen"),
                this.$t("QuyenBanVe.TuyChonIn2Lien"),
            );
            let BatBuocPhatHanhVe = this.$Helper.KiemTraQuyen(
                this.$t("QuyenBanVe.BanVeTaiBen"),
                this.$t("QuyenBanVe.BatBuocPhatHanhVe"),
            );
            let BatBuocInVe = this.$Helper.KiemTraQuyen(
                this.$t("QuyenBanVe.BanVeTaiBen"),
                this.$t("QuyenBanVe.BatBuocInVe"),
            );
            let BatBuocIn2Lien = this.$Helper.KiemTraQuyen(
                this.$t("QuyenBanVe.BanVeTaiBen"),
                this.$t("QuyenBanVe.BatBuocIn2Lien"),
            );
            let KhongChoPhepPhatHanhVe = this.$Helper.KiemTraQuyen(
                this.$t("QuyenBanVe.BanVeTaiBen"),
                this.$t("QuyenBanVe.KhongChoPhepPhatHanhVe"),
            );
            let KhongChoPhepInVe = this.$Helper.KiemTraQuyen(
                this.$t("QuyenBanVe.BanVeTaiBen"),
                this.$t("QuyenBanVe.KhongChoPhepInVe"),
            );
            let KhongChoPhepIn2Lien = this.$Helper.KiemTraQuyen(
                this.$t("QuyenBanVe.BanVeTaiBen"),
                this.$t("QuyenBanVe.KhongChoPhepIn2Lien"),
            );

            if (KhongChoPhepPhatHanhVe) {
                this.QuyenTuyChonPhatHanhVe = false;
                this.CheckPhatHanhVe = false;
            } else {
                if (BatBuocPhatHanhVe) {
                    this.QuyenTuyChonPhatHanhVe = false;
                    this.CheckPhatHanhVe = true;
                } else {
                    let CheckPhatHanhVe = localStorage.getItem("CheckPhatHanhVe");
                    this.CheckPhatHanhVe = !CheckPhatHanhVe
                        ? false
                        : CheckPhatHanhVe === "true";
                }
            }

            if (KhongChoPhepInVe) {
                this.QuyenTuyChonInVe = false;
                this.CheckInVe = false;
            } else {
                if (BatBuocInVe) {
                    this.QuyenTuyChonInVe = false;
                    this.CheckInVe = true;
                } else {
                    let CheckInVe = localStorage.getItem("CheckInVe");
                    this.CheckInVe = !CheckInVe ? false : CheckInVe === "true";
                }
            }

            if (KhongChoPhepIn2Lien) {
                this.QuyenTuyChonIn2Lien = false;
                this.CheckIn2Lien = false;
            } else {
                if (BatBuocIn2Lien) {
                    this.QuyenTuyChonIn2Lien = false;
                    this.CheckIn2Lien = true;
                } else {
                    let CheckIn2Lien = localStorage.getItem("CheckIn2Lien");
                    this.CheckIn2Lien = !CheckIn2Lien ? false : CheckIn2Lien === "true";
                }
            }
        },
        async LayChiTietCauHinhDoanhNghiepTheoMaCauHinh() {
            try {
                this.$startLoading;
                await this.$store.dispatch(
                    "DVVTLenh/LayChiTietCauHinhDoanhNghiepTheoMaCauHinh",
                    {
                        DanhSachMaCauHinhCanLay: [
                            this.$t(
                                "MaCauHinhDoanhNghiep.DoanhNghiepSuDungHoaDonBanHangCoGiamTru",
                            ),
                        ],
                    },
                );
            } catch (error) {
                console.log("🚀 ~ error:", error);
            } finally {
                this.$stopLoading;
            }
        },
    },
    created() {
        onIonViewWillEnter(() => {
            this.isXuLy = false;
            this.state = "loading";

            this.KiemTraQuyenBanVe();
            this.LayChiTietCauHinhDoanhNghiepTheoMaCauHinh();

            if (this.reloadThongTinBanVe) {
                this.getData();
                this.Set({ key: "reloadThongTinBanVe", data: false });
            }
        });
    },
};
</script>

<style scoped>
.row-don-gia {
    border-bottom: 1px solid #dadce0;
    padding: 4px 0;
}
.row-thanh-tien {
    padding: 6px 0 4px;
}
</style>
